/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
// @ts-check
// in your cookie banner
import { Flex, Link, Text } from '@bottlebooks/gatsby-design-system';
import { Button } from '@bottlebooks/gatsby-theme-base';
import { useLocation } from '@reach/router'; // this helps tracking the location
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
import React from 'react';
import { jsx } from 'theme-ui';
import Cookies from 'universal-cookie';

const COOKIE_NAME = 'bottlebooks-online-directory-gdpr';

export default function CookieBanner({ ...rest }) {
  const location = useLocation();
  const cookies = new Cookies();
  const [isVisible, setIsVisible] = React.useState(!cookies.get(COOKIE_NAME));

  if (!isVisible) return null;
  return (
    <Flex
      direction={['column', 'row']}
      align="center"
      justify="space-between"
      sx={{
        padding: 4,
        backgroundColor: 'primary',
        position: 'relative',
        minHeight: [225, 0],
        backgroundColor: '#dedde3',
        boxShadow: 'inset 0 100px 80px -100px rgb(0 0 0 / 10%)',
      }}
      {...rest}
    >
      <Text>
        Diese Website verwendet Cookies, um die Benutzerfreundlichkeit zu
        verbessern und Ihnen ein Einkaufserlebnis zu ermöglichen.{' '}
        <Link to={'/datenschutz'} sx={{ textDecoration: 'underline' }}>
          Erfahren Sie mehr.
        </Link>
      </Text>
      <Flex
        direction={['column', 'row-reverse']}
        align="center"
        sx={{ width: [null, 300], flexShrink: 0 }}
      >
        <Button
          variant="secondary"
          sx={{
            margin: 2,
            borderColor: '#98002e',
            backgroundColor: 'primary',
            border: 1,
            color: 'white',
            paddingX: 3,
            borderRadius: 'round',
          }}
          onClick={() => {
            cookies.set(COOKIE_NAME, true, {
              // Don't ask again this year
              maxAge: 3600 * 365,
            });
            cookies.set('gatsby-gdpr-google-analytics', true);
            // Close this window
            setIsVisible(false);
            initializeAndTrack(location);
          }}
        >
          Ich akzeptiere
        </Button>
        <Link
          onClick={() => {
            // Close this window
            setIsVisible(false);
            return cookies.set(COOKIE_NAME, true, {
              // Ask again after an hour
              maxAge: 3600,
            });
          }}
          sx={{ textDecoration: 'underline', margin: 2 }}
        >
          <Text>Ich lehne ab</Text>
        </Link>
      </Flex>
    </Flex>
  );
}
