/** @jsxRuntime classic */
/** @jsx jsx */
// @ts-check
import {
  Box,
  Flex,
  Label,
  Link,
  P,
  SemiTitle,
  Text,
  Stack,
} from '@bottlebooks/gatsby-design-system';
import { Button } from '@bottlebooks/gatsby-theme-base';
import Image from 'gatsby-plugin-sanity-image';
import Layout from '@bottlebooks/gatsby-theme-event/src/components/Layout/Layout';
import useLink from '@bottlebooks/gatsby-theme-event/src/useLink';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';
import ProductSnipcartButton from '../../@bottlebooks/gatsby-theme-event/components/Shop/ProductSnipcartButton';
import { RichText, Section } from '../../cms-design-system';
import dayjs from 'dayjs';
// Just have to load this
import 'dayjs/locale/de';
import WineListItem from '../../components/WineListItem';
import { useLingui } from '@lingui/react';
// Set globally
dayjs.locale('de');

export default function PackagePage({ data }) {
  const packageItem = afterQuery(data);
  if (!data) return null;
  return (
    <Layout
      page={{
        // title: `Online-Verkostung Nr. ${packageItem.packageNumber} ${packageItem.name}`,
        title: `Tasting ${packageItem.name}`,
      }}
    >
      <PackageHeaderSection packageItem={packageItem} />
      <WinesSection packageItem={packageItem} />
      <SpeakerSection packageItem={packageItem} />
      <HowToSection packageItem={packageItem} variant="dark" />
    </Layout>
  );
}

function PackageHeaderSection({ packageItem, ...rest }) {
  const isb2b = packageItem.customerSegment === 'b2b';

  const { i18n } = useLingui();
  return (
    <Section {...rest}>
      <Flex direction={['column', null, 'row']}>
        <Box sx={{ width: 200, flexGrow: 0, flexShrink: 0 }}>
          {packageItem.packageImage && (
            <Image
              {...packageItem.packageImage}
              width={200}
              sx={{ width: '100%' }}
              config={{ quality: 100 }}
            />
          )}
        </Box>
        <Box sx={{ marginLeft: [0, null, 4] }}>
          <Text>Online-Tasting</Text>
          <Section.Title>{packageItem.name}</Section.Title>
          <Text>
            {packageItem.subtitle} {packageItem.speakerIntroduction}
            {/* am{' '}
            {i18n.date(packageItem.webinarDate, { weekday: 'long' })}, den{' '}
            {i18n.date(packageItem.webinarDate, { dateStyle: 'medium' })} um{' '}
            {i18n.date(packageItem.webinarDate, { timeStyle: 'short' })} Uhr. */}
          </Text>
          <Text sx={{ color: 'text', flexGrow: 1 }}>
            {packageItem.products.length === 4 ? (
              <Text sx={{ color: 'text', flexGrow: 1 }}>
                Beinhaltet vier 50ml-Probierfläschchen.
              </Text>
            ) : (
              <Text sx={{ color: 'text', flexGrow: 1 }}>
                Beinhaltet drei 50ml-Probierfläschchen.
              </Text>
            )}
          </Text>
          {isb2b ? (
            <SemiTitle>kostenlos inklusive Versand</SemiTitle>
          ) : (
            <SemiTitle>
              €{packageItem.price?.toFixed(2).replace('.', ',')} inkl. MwSt.
              zzgl. Versandkosten
            </SemiTitle>
          )}
          <Box sx={{ marginY: 3 }}>
            <ProductSnipcartButton
              product={{
                ...packageItem,
                productId: packageItem.slug.current,
                fullName: `Nr. ${packageItem.packageNumber}: ${packageItem.name}`,
                description: packageItem.description,
                shopImage: {
                  fixed: {
                    src: packageItem.imageUrl,
                  },
                },
              }}
            />
          </Box>
          <RichText>{packageItem.description}</RichText>
        </Box>
        <AtAGlance
          packageItem={packageItem}
          sx={{
            width: 300,
            flexShrink: 0,
            marginLeft: [0, null, 3],
            marginBottom: 3,
          }}
        />
      </Flex>
    </Section>
  );
}

function AtAGlance({ packageItem, ...rest }) {
  const { i18n } = useLingui();
  return (
    <Box {...rest}>
      <SemiTitle sx={{ marginTop: 0, paddingTop: 0 }}>
        Alle Daten auf einen Blick
      </SemiTitle>
      <Stack align="flex-start">
        <Box>
          <Label>Datum</Label>
          <Text>
            {packageItem.webinarDateDescription}
            {/* {i18n.date(packageItem.webinarDate, { weekday: 'long' })},{' '}
            {i18n.date(packageItem.webinarDate, { dateStyle: 'medium' })} */}
          </Text>
        </Box>
        {/* <Box>
          <Label>Uhrzeit</Label>
          <Text>
            {i18n.date(packageItem.webinarDate, { timeStyle: 'short' })}
          </Text>
        </Box> */}
        <Box>
          <Label>{packageItem.speaker?.title}</Label>
          <Text>{packageItem.speaker?.name}</Text>
        </Box>
        <Button
          variant="primary"
          href={packageItem.webinarJoinUrl}
          target="_blank"
          sx={{ borderRadius: 'round' }}
        >
          Direkt zur Terminansicht ohne Weinbestellung
        </Button>
      </Stack>
    </Box>
  );
}

function WinesSection({ packageItem, ...rest }) {
  const link = useLink();
  return (
    <Section {...rest}>
      <Section.Title sx={{ marginBottom: 3 }}>
        Auf diese Probierfläschchen (50ml) von folgenden Weinen dürfen Sie sich
        freuen
      </Section.Title>
      <Section.Body>
        {packageItem.products.map((product) => {
          // Calculate name that conforms to German rules
          const { vintage, name, classification, designation } = product;
          const nameWithVintage = [vintage, name].filter(Boolean).join(' | ');
          const productName = [nameWithVintage, classification, designation]
            .filter(Boolean)
            .join(', ');
          return (
            <WineListItem
              key={product?.productId}
              // Vintage is included in name
              product={{
                ...product,
                vintage: null,
                shortName: productName,
              }}
              to={link.exhibitor(product?.exhibitor)}
              sx={{ color: 'text' }}
            />
          );
        })}
        <Box sx={{ marginTop: 3 }}>
          <Text variant="small">
            Weine enthalten Sulfite, Preis beinhaltet Online-Tasting mit
            Gratis-Mustern, Musterflascheninhalt je 50ml.
          </Text>
        </Box>
      </Section.Body>
    </Section>
  );
}

function SpeakerSection({ packageItem, ...rest }) {
  return (
    <Section {...rest}>
      <Section.Title sx={{ marginBottom: 3 }}>
        Verkostung {packageItem.speaker?.introduction}
      </Section.Title>
      <Section.Body>
        <Flex direction={['column', null, 'row']}>
          {packageItem.speaker?.profileImage && (
            <Image
              {...packageItem.speaker?.profileImage}
              width={340}
              height={340}
              sizes="(max-width: 640px) 340px, 340px"
              sx={{
                width: 340,
                height: 340,
                display: 'block',
                flexShrink: 0,
                marginBottom: 3,
                marginRight: [0, null, 3],
              }}
            />
          )}
          <Box>
            <RichText>{packageItem.speaker?.description}</RichText>
            {packageItem.speaker?.externalUrl && (
              <Button
                href={packageItem.speaker?.externalUrl}
                target="_blank"
                variant="outline"
                sx={{ border: 0, marginY: 3 }}
              >
                Mehr über {packageItem.speaker?.name}
              </Button>
            )}
          </Box>
        </Flex>
      </Section.Body>
    </Section>
  );
}

function HowToSection({ packageItem, ...rest }) {
  const { i18n } = useLingui();
  return (
    <Section {...rest}>
      <Section.Title sx={{ marginBottom: 3 }}>FAQ Online-Tasting</Section.Title>
      <Section.Body>
        <Flex direction={['column', null, 'row']} justify="space-between">
          <Box sx={{ flexShrink: 1, marginBottom: 3 }}>
            <P>
              <Text sx={{ color: 'text', width: '400px' }}>
                Bitte bestellen Sie zuerst die Weine über den Warenkorb und
                suchen sich erst nach erfolgter Lieferung den passenden Termin
                aus. Der Versand erfolgt in der Regel innerhalb von 7 Tagen nach
                Bestelleingang.
              </Text>
            </P>
            <P>
              <Text sx={{ color: 'text', width: '400px' }}>
                Termine werden täglich angeboten. Den Link zur Terminbuchung für
                Ihr Online-Tasting erhalten Sie sofort nach abgeschlossener
                Bestellung automatisch in einer separaten E-Mail. Sollten Sie an
                einem Termin nicht teilnehmen können, können Sie problemlos
                einen Ersatztermin über den Link in der E-Mail buchen.
              </Text>
            </P>
            <P>
              <Text sx={{ color: 'text', width: '400px' }}>
                Sie können auch ohne zu bestellen den Link rechts oben klicken:
                "Direkt zur Terminauswahl". In diesem Fall buchen Sie jedoch NUR
                einen Zuschauerplatz ohne Verkostung.
              </Text>
            </P>
            <P>
              <Link to="/faqs">Weitere Fragen?</Link>
            </P>
          </Box>
        </Flex>
      </Section.Body>
    </Section>
  );
}

export const query = graphql`
  query ($id: String) {
    sanityPackageItem(id: { eq: $id }) {
      _id
      slug {
        current
      }
      name
      packageImage {
        ...ImageWithPreview
      }
      subtitle
      description: _rawDescription
      packageNumber
      price
      speaker {
        name
        title
        introduction
        description: _rawDescription
        externalUrl
        profileImage {
          ...ImageWithPreview
        }
      }
      #webinarDate // Not used for WeinClub, webinarDateDescription used instead
      webinarDateDescription
      #webinarId // Not used for WeinClub
      #webinarSchedule // Not used for WeinClub
      webinarJoinUrl
      customerSegment
      productStands
      products {
        ...WineListItem
        exhibitor {
          slug {
            current
          }
        }
      }
      ...sanity_ProductSnipcartButton
    }
  }
`;

function afterQuery(data) {
  if (!data?.sanityPackageItem) return null;
  return {
    ...data.sanityPackageItem,
    pricing: { price: data.sanityPackageItem.price },
  };
}
