/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
// @ts-check
import {
  Box,
  CloseIcon,
  Flex,
  Grid,
  Link,
  SemiTitle,
  Text,
  Title,
} from '@bottlebooks/gatsby-design-system';
import { Button } from '@bottlebooks/gatsby-theme-base';
import Layout from '@bottlebooks/gatsby-theme-event/src/components/Layout/Layout';
import dayjs from 'dayjs';
import { graphql } from 'gatsby';
import React from 'react';
import { jsx } from 'theme-ui';
import sliderImage from '../assets/WeinClubHeader.jpeg';
import { RichText, Section, Slider } from '../cms-design-system';
import PackageListItem from '../components/PackageListItem';
// Just have to load this
import 'dayjs/locale/de';
import SanityImage from 'gatsby-plugin-sanity-image';
// Set globally
dayjs.locale('de');

export default function PackagesPage({ data }) {
  const { packages, uniqueDates, uniqueSpeakers, categories, speakers } =
    afterQuery(data);
  /** @type {[string | undefined, import('react').Dispatch<import('react').SetStateAction<string | undefined>>]} */
  const [selectedCategory, setSelectedCategory] = React.useState();

  const filteredPackages = selectedCategory
    ? packages.filter((packageItem) =>
        packageItem.categories.includes(selectedCategory)
      )
    : packages;
  const groupedByDate = Object.values(
    filteredPackages.reduce((acc, packageItem) => {
      if (!acc[packageItem.eventFormattedDate])
        acc[packageItem.eventFormattedDate] = {
          date: packageItem.eventFormattedDate,
          packages: [],
        };
      acc[packageItem.eventFormattedDate].packages.push(packageItem);
      return acc;
    }, {})
  );
  return (
    <Layout page={{ title: 'Online-Tastings' }}>
      <Slider imageUrl={sliderImage} />
      <Section sx={{ paddingTop: 4 }}>
        <Section.Body>
          <Grid
            gap={4}
            sx={{ gridTemplateColumns: ['1fr', null, '400px 1fr'] }}
          >
            <Filters
              uniqueDates={uniqueDates}
              uniqueSpeakers={uniqueSpeakers}
              speakers={speakers}
              categories={categories}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
            />
            <Results
              groupedByDate={groupedByDate}
              selectedCategory={selectedCategory}
              uniqueSpeakers={uniqueSpeakers}
              speakers={speakers}
              sx={{ alignSelf: 'start' }}
            />
          </Grid>
        </Section.Body>
      </Section>
    </Layout>
  );
}

function Filters({
  uniqueDates,
  uniqueSpeakers,
  speakers,
  categories,
  selectedCategory,
  setSelectedCategory,
  ...rest
}) {
  return (
    <Box columns={[1, null]} {...rest}>
      {selectedCategory && (
        <Box sx={{ paddingY: 3 }}>
          <Flex>
            <TagButton
              isSelected={true}
              onClick={() => setSelectedCategory(undefined)}
              sx={{ paddingRight: 2, marginBottom: 0 }}
            >
              {selectedCategory}
              <CloseIcon
                size="xxsmall"
                sx={{ marginLeft: 2, marginTop: -0.5 }}
              />
            </TagButton>
          </Flex>
        </Box>
      )}
      <Section.Title>Online-Tastings</Section.Title>
      <Text sx={{ color: 'text' }}>
        Entdecken Sie die Weine der weltbesten Weingüter bei unseren
        Online-Tastings. Für Sie stehen immer mehrere Termine je Tasting zur
        Auswahl. Den gewünschten Termin wählen Sie nach Abschluss des
        Bestellvorganges aus. Im Vorfeld schicken wir Ihnen die Weinproben für
        Ihr gewähltes Online-Tasting zu.{' '}
        <i>Die Teilnehmerzahl ist begrenzt. Schnell sein lohnt sich also.</i>
      </Text>
    </Box>
  );
}

function Results({
  groupedByDate,
  uniqueSpeakers,
  selectedCategory,
  speakers,
  ...rest
}) {
  const selectedItem =
    selectedCategory &&
    speakers.find((aSpeaker) => aSpeaker?.name === selectedCategory);
  return (
    <Box {...rest}>
      {selectedItem && (
        <SpeakerIntro speaker={selectedItem} sx={{ marginBottom: 3 }} />
      )}
      <Grid gap={3} columns={1}>
        {groupedByDate.map((dateGroup) => (
          <Box key={dateGroup.date}>
            {dateGroup.packages?.map((packageItem) => (
              <PackageListItem
                key={packageItem.slug.current}
                packageItem={packageItem}
                sx={{ marginBottom: 3 }}
              />
            ))}
          </Box>
        ))}
      </Grid>
    </Box>
  );
}

function afterQuery(data) {
  const allPackages = data?.allSanityPackageItem?.nodes;
  const visiblePackages = allPackages.filter(
    ({ isVisible, customerSegment }) => isVisible && customerSegment === 'b2c'
  );
  const uniqueSpeakers = visiblePackages.reduce((acc, packageItem) => {
    const speakerName = packageItem.speaker?.name || 'FEHLT';
    if (!acc[speakerName])
      acc[speakerName] = {
        name: speakerName,
        count: 0,
      };
    acc[speakerName].count++;

    // Host is the 2. speaker
    // Not every event has a host
    const hostName = packageItem?.host?.name;
    if (hostName) {
      if (!acc[hostName])
        acc[hostName] = {
          name: hostName,
          count: 0,
        };
      acc[hostName].count++;
    }
    return acc;
  }, {});

  const categories = visiblePackages.reduce((acc, packageItem) => {
    packageItem.categories.map((category) => {
      if (!acc[category]) acc[category] = { name: category, count: 0 };
      acc[category].count++;
    });
    return acc;
  }, {});

  const enrichedPackages = visiblePackages.map((packageItem) => {
    const eventFormattedDate = dayjs(packageItem.webinarDate).format(
      'DD.MM.YYYY'
    );
    const enrichedCategories = [
      eventFormattedDate,
      ...packageItem.categories,
      packageItem.speaker?.name,
    ];
    return {
      ...packageItem,
      eventFormattedDate,
      categories: enrichedCategories,
    };
  });
  const uniqueDates = enrichedPackages.reduce((acc, packageItem) => {
    const value = packageItem.eventFormattedDate;
    if (!acc[value])
      acc[value] = {
        name: value,
        count: 0,
      };
    acc[value].count++;
    return acc;
  }, {});

  return {
    packages: enrichedPackages,
    uniqueDates: Object.values(uniqueDates),
    speakers: [].concat(
      visiblePackages.map((aPackage) => aPackage.speaker)
      // packages.map((aPackage) => aPackage.host)
    ),
    uniqueSpeakers: Object.values(uniqueSpeakers).sort((a, b) =>
      a.sortOrder > b.sortOrder ? 1 : -1
    ),
    categories: Object.values(categories),
  };
}

function SpeakerButton({ speaker, onClick, isSelected, ...rest }) {
  return (
    <Button
      variant="default"
      onClick={onClick}
      sx={(theme) => ({
        padding: 0,
        position: 'relative',
        overflow: 'hidden',
        boxShadow: isSelected ? `0 0 0 1px ${theme.colors?.primary}` : 'none',
        ':hover > div': { transform: 'translateY(-100%)' },
      })}
      {...rest}
    >
      {speaker?.profileImage && (
        <SanityImage
          {...speaker?.profileImage}
          alt={speaker?.name}
          width={180}
          height={180}
          sizes="180px"
          sx={{
            position: 'absolute',
            top: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
      )}
      <Box
        sx={{
          position: 'absolute',
          top: '100%',
          width: '100%',
          backgroundColor: 'white',
          transition: 'transform 0.2s ease-in-out',
          transform: isSelected ? 'translateY(-100%)' : undefined,
        }}
      >
        <Text variant="smallest" sx={{ padding: 2 }}>
          {speaker?.name}
        </Text>
      </Box>
    </Button>
  );
}

function TagButton({ children, onClick, isSelected, ...rest }) {
  return (
    <Button
      variant={isSelected ? 'primary' : 'default'}
      onClick={onClick}
      sx={{
        borderRadius: 'round',
        border: 0,
        paddingX: 3,
        paddingY: '3px',
        marginBottom: 3,
        marginRight: 3,
        backgroundColor: isSelected ? 'primary' : '#f3f3f3',
        color: isSelected ? 'white' : 'black',
      }}
      {...rest}
    >
      {children}
    </Button>
  );
}

function SpeakerIntro({ speaker, ...rest }) {
  return (
    <Box sx={{ border: 1, borderColor: 'border', padding: 2 }} {...rest}>
      <Title variant="smallest" sx={{ marginBottom: 3, color: 'primary' }}>
        Events {speaker?.introduction}
      </Title>
      <Box sx={{ display: [null, null, null, 'flex'] }}>
        {speaker?.profileImage && (
          <SanityImage
            {...speaker?.profileImage}
            alt={speaker?.name}
            width={240}
            height={240}
            sizes="240px"
            sx={{
              width: [240, null, 180, 240],
              height: [240, null, 180, 240],
              display: 'block',
              marginRight: 3,
              marginBottom: 3,
              float: ['none', 'left'],
            }}
          />
        )}
        <Box>
          <Text variant="small" sx={{ marginBottom: 2 }}>
            <RichText>{speaker?.description}</RichText>
          </Text>
          {speaker?.externalUrl && (
            <Button
              href={speaker?.externalUrl}
              target="_blank"
              variant="outline"
              sx={{ border: 0, marginY: 3 }}
            >
              Mehr über {speaker?.name}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export const query = graphql`
  query PackagesPage {
    allSanityPackageItem(sort: { fields: [packageNumber], order: [ASC] }) {
      nodes {
        _id
        slug {
          current
        }
        name
        categories
        webinarDate
        speaker {
          ...SpeakerProfile
        }
        # host {
        #   ...SpeakerProfile
        # }
        ...PackageListItem
        isVisible
      }
    }
  }
  fragment SpeakerProfile on SanitySpeaker {
    id
    name
    introduction
    profileImage {
      ...ImageWithPreview
    }
    description: _rawDescription
    externalUrl
  }
`;
