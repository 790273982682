/** @jsxRuntime classic */
/** @jsx jsx */
// @ts-check
import {
  Text,
  Box,
  Button,
  Flex,
  Link,
  SearchIcon,
  StarIcon,
} from '@bottlebooks/gatsby-theme-base';
import { jsx } from 'theme-ui';
import useLink from '@bottlebooks/gatsby-theme-event/src/useLink';
import { ShoppingCartIndicator } from '@bottlebooks/gatsby-theme-event/src/components/Shop/ShoppingCartMenu';
// import LanguageSwitcher from './LanguageSwitcher';
import MultisiteSwitcher from '@bottlebooks/gatsby-theme-event/src/components/Layout/MultisiteSwitcher';
import TopMenuItems from '@bottlebooks/gatsby-theme-event/src/components/Layout/TopMenuItems';
import MenuItem from './MenuItem';

export default function MenuActions({
  searchIsVisible = false,
  toggleSearchIsVisible = (state) => {},
  ...rest
}) {
  const link = useLink();
  return (
    <Flex
      // direction="row-reverse"
      align="center"
      sx={{ flexGrow: 1, justifyContent: 'flex-end' }}
      {...rest}
    >
      <Button
        variant="text"
        onClick={() => toggleSearchIsVisible(!searchIsVisible)}
        sx={{ marginRight: 2, display: [null, null, 'none'] }}
      >
        <SearchIcon sx={{ height: '30px', width: '30px' }} />
      </Button>
      <TopMenuItems sx={{ marginRight: 2 }} />
      {/* <LanguageSwitcher /> */}
      {process.env.GATSBY_MULTISITES && (
        <MultisiteSwitcher sx={{ marginRight: 2 }} />
      )}
      {/* <Button
        variant="primary"
        to="/tickets"
        sx={{
          marginRight: 3,
          display: ['none', null, 'block'],
        }}
      >
        Tickets kaufen
      </Button> */}
      {/* <Button
        variant="outline"
        href="https://weintour.net"
        sx={{
          marginRight: 2,
          display: ['none', null, 'block'],
        }}
      >
        WeinTour.net
      </Button> */}
      <MenuItem
        to={'/packages'}
        sx={{ marginRight: 2, display: [null, null, 'none'], paddingX: 0 }}
      >
        Verkostungen
      </MenuItem>
      {process.env.GATSBY_SNIPCART_API_KEY && <ShoppingCartIndicator />}
    </Flex>
  );
}
