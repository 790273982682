/** @jsxRuntime classic */
/** @jsx jsx */
// @ts-check
import Layout from '@bottlebooks/gatsby-theme-event/src/components/Layout/Layout';
import { graphql, useStaticQuery } from 'gatsby';
import { jsx } from 'theme-ui';
import * as cms from '../cms';
import * as designSystem from '../cms-design-system';

export default function CaliforniaLandingPage() {
  const {
    event: { eventId },
  } = useStaticQuery(graphql`
    {
      event {
        eventId
      }
    }
  `);
  const data = cms[`event_${eventId}`].index;
  return (
    <Layout>
      {data.sections.map((section, index) => {
        const Component = designSystem[section.component];
        return <Component key={index} {...section.data} sx={section.style} />;
      })}
    </Layout>
  );
}
