/** @jsxRuntime classic */
/** @jsx jsx */
import { gql } from '@apollo/client';
import { Button } from '@bottlebooks/gatsby-theme-base';
import ProductPrice from '@bottlebooks/gatsby-theme-event/src/components/Product/ProductPrice/ProductPrice';
import ShoppingCartButton from '@bottlebooks/gatsby-theme-event/src/components/Shop/ShoppingCartButton';
import Product_fullName from '@bottlebooks/gatsby-theme-event/src/fragments/Product/fullName';
import useSnipcart from '@bottlebooks/gatsby-theme-event/src/useSnipcart';
import { Trans } from '@lingui/macro';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';
import useSiteMetadata from '../../../../useSiteMetadata';

export default function ProductSnipcartButton({ product, ...rest }) {
  const { isItemInCart, canAddMoreProducts } = useSnipcart();
  const isb2b = product.customerSegment === 'b2b';
  const baseId = product.inventoryId || product.productId;
  // append b2b to id if b2b
  const uniqueId = `${baseId}-Wein.Club${isb2b ? `-b2b` : ''}
  }`;
  if (isItemInCart(uniqueId))
    return (
      <ProductSnipcartButtonBase
        product={product}
        variant="outline"
        uniqueId={uniqueId}
        isb2b={isb2b}
        // disabled
        {...rest}
      >
        <Trans>Already in cart</Trans>
      </ProductSnipcartButtonBase>
    );

  if (product.status === 'out-of-stock')
    return (
      <ProductSnipcartButtonBase
        product={product}
        variant="outline"
        uniqueId={uniqueId}
        isb2b={isb2b}
        disabled
        {...rest}
      >
        <Trans>Ausverkauft</Trans>
      </ProductSnipcartButtonBase>
    );

  if (!canAddMoreProducts)
    return (
      <ProductSnipcartButtonBase
        product={product}
        variant="outline"
        uniqueId={uniqueId}
        isb2b={isb2b}
        disabled
        {...rest}
      >
        <Trans>Cart is full</Trans>
      </ProductSnipcartButtonBase>
    );

  return (
    <ProductSnipcartButtonBase
      product={product}
      uniqueId={uniqueId}
      isb2b={isb2b}
      {...rest}
    >
      <Trans>Add to cart</Trans>
      <ProductPrice
        pricing={product.pricing}
        sx={{ paddingLeft: 2, opacity: 0.8 }}
      />
    </ProductSnipcartButtonBase>
  );
}

function ProductSnipcartButtonBase({
  product,
  uniqueId,
  isb2b,
  children,
  ...rest
}) {
  // const { url } = useSiteMetadata();
  const url = 'http://events.wein.plus';
  return (
    <ShoppingCartButton
      data-item-id={uniqueId}
      data-item-url={`${url}/packages/${product.slug?.current}`}
      data-item-name={product.fullName}
      data-item-description={product.products

        ?.map(({ name, vintage }) => `${name} ${vintage}`)
        .join(' | ')}
      data-item-image={product.shopImage?.fixed?.src}
      // Used for discount codes
      data-item-categories="OV"
      // No all products may have set a price
      // Is there a better way to handle this? Should it be disabled?
      data-item-price={isb2b ? 0 : product?.pricing?.price}
      data-item-has-taxes-included="true"
      // Snipcart uses the weight to determine the shipping cost.
      // Defaulting to 500g for consumer
      data-item-weight={isb2b ? 0 : 500}
      {...rest}
      className={`snipcart-add-item ${rest.className}`}
      data-item-custom1-name="itemGroup"
      data-item-custom1-type="hidden"
      data-item-custom1-value="package"
      // !! This was previously custom1 -- not sure that this still works with the hooks
      data-item-custom2-name="webinarJoinUrl"
      data-item-custom2-type="hidden"
      data-item-custom2-value={product.webinarJoinUrl}
      sx={{ borderRadius: 'round' }}
    >
      {children}
    </ShoppingCartButton>
  );
}

export const fragment = graphql`
  fragment sanity_ProductSnipcartButton on SanityPackageItem {
    webinarId
    webinarSchedule
    webinarJoinUrl
    price
    status
    slug {
      current
    }
    products {
      name
      vintage
    }
  }
`;

ProductSnipcartButton.fragment = gql`
  fragment ProductSnipcartButton on Product {
    inventoryId
    productId
    description
    shopImage: bottleImage {
      fixed(width: 80, height: 80, crop: PAD, background: "#FFF0") {
        src
      }
    }
    ...Product_fullName
  }
  ${Product_fullName.fragment}
`;
