/** @jsx jsx */
import {
  Box,
  Flex,
  Label,
  Text,
  TwitterIcon,
  FacebookIcon,
  InstagramIcon,
  YoutubeIcon,
  Grid,
} from '@bottlebooks/gatsby-design-system';
import { BottlebooksLogo, Link } from '@bottlebooks/gatsby-theme-base';
import { Trans } from '@lingui/macro';
import { jsx } from 'theme-ui';
// @ts-ignore TS doesn't like direct image imports.
import backToTop from '@bottlebooks/gatsby-theme-event/src/assets/back-to-top.svg';

// What is the best way to get the site configuration into here?
import { socialMedia, sponsors } from '../../../../cms/siteConfiguration';

export default function Footer({ children, homePath = '', ...rest }) {
  return (
    <Wrapper
      sx={{
        backgroundColor: '#dedde3',
        boxShadow: 'inset 0 100px 80px -100px rgb(0 0 0 / 10%)',
      }}
      {...rest}
    >
      <Top sx={{ marginTop: 4 }}>{children}</Top>
      {/* <Bottom /> */}
      {/* <Logos /> */}
      <FooterLinks sx={{ marginY: 3 }} />
    </Wrapper>
  );
}

export function Wrapper({ children, ...rest }) {
  return (
    <Box
      as="footer"
      sx={{
        variant: 'styles.Footer',
        paddingY: [2, 4],
        paddingX: [2, 5],
        backgroundColor: 'background',
      }}
      {...rest}
    >
      {children}
    </Box>
  );
}

export function Top({ children, ...rest }) {
  function handleScrollToTop(e) {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    e.preventDefault();
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        paddingBottom: [2, 4],
        borderBottom: 1,
        borderColor: 'border',
      }}
      {...rest}
    >
      {children}
      <Link
        href="#"
        onClick={handleScrollToTop}
        sx={{ marginLeft: 'auto', color: 'black' }}
      >
        <Label>
          <Trans>Back to the top</Trans>{' '}
          <img src={backToTop} alt="" sx={{ verticalAlign: 'middle' }} />
        </Label>
      </Link>
    </Box>
  );
}

function Logos() {
  const widths = [60, 70, 110, 140];
  return (
    <Flex
      justify="space-between"
      align="center"
      sx={{ borderBottom: 1, borderColor: 'border' }}
    >
      {sponsors.map((sponsor, index) => (
        <Link
          key={`${sponsor.name}_${index}`}
          href={sponsor.url}
          target="_blank"
        >
          <img src={sponsor.imageUrl} sx={{ width: widths }} height="100%" />
        </Link>
      ))}
    </Flex>
  );
}

function FooterLinks({ ...rest }) {
  return (
    <Grid columns={1} {...rest}>
      <Flex gap={3} justify="center">
        {socialMedia.map((socialMediaItem, index) => (
          <Link
            key={`${socialMediaItem.type}_${index}`}
            href={socialMediaItem.url}
            sx={{ width: 30 }}
          >
            <SocialMediaItem item={socialMediaItem} />
          </Link>
        ))}
      </Flex>
      <Flex
        direction={['column', 'row', 'row', 'row']}
        justify="center"
        align="center"
      >
        <Link to={'/faqs'} sx={{ marginX: 2 }}>
          <Text variant="small" sx={{ color: '#555555' }}>
            FAQ
          </Text>
        </Link>
        <Link to={'/kontakt'} sx={{ marginX: 2 }}>
          <Text variant="small" sx={{ color: '#555555' }}>
            Kontakt
          </Text>
        </Link>
        <Link to={'/impressum'} sx={{ marginX: 2 }}>
          <Text variant="small" sx={{ color: '#555555' }}>
            Impressum
          </Text>
        </Link>
        <Link to={'/datenschutz'} sx={{ marginX: 2 }}>
          <Text variant="small" sx={{ color: '#555555' }}>
            Datenschutzerklärung
          </Text>
        </Link>
        <Link to={'/agb'} sx={{ marginX: 2 }}>
          <Text variant="small" sx={{ color: '#555555' }}>
            Allg. Geschäftsbedingungen
          </Text>
        </Link>
      </Flex>
    </Grid>
  );
}

function SocialMediaItem({ item }) {
  switch (item.type) {
    case 'TWITTER':
      return <TwitterIcon size="small" />;
    case 'INSTAGRAM':
      return <InstagramIcon size="small" />;
    case 'FACEBOOK':
      return <FacebookIcon size="small" />;
    case 'YOUTUBE':
      return <YoutubeIcon size="small" />;
    default:
      return null;
  }
}

export function Bottom({ ...rest }) {
  return (
    <Flex align="baseline" gap={2} sx={{ paddingTop: [2, 4] }} {...rest}>
      <Text variant="smallest" sx={{ color: 'lightText' }}>
        <Flex align="center">
          <Trans>Powered by</Trans>{' '}
          <Link
            href="https://www.bottlebooks.me"
            external
            sx={{ textTransform: 'uppercase' }}
          >
            <BottlebooksLogo title="Bottlebooks®" />
          </Link>
        </Flex>
        <Text variant="smallest">
          <Trans>Data management for the wine and drinks industry</Trans>
        </Text>
      </Text>

      <Text variant="smallest" sx={{ marginLeft: 'auto', color: 'lightText' }}>
        2021
      </Text>
    </Flex>
  );
}
