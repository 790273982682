// @ts-check
import { graphql, useStaticQuery } from 'gatsby';

/**
 * @returns
 */
export default function usePackages() {
  const data = useStaticQuery(graphql`
    query usePackages {
      allSanityPackageItem {
        nodes {
          productId: _id
          ...PackageListItem
          # A hack to make a package behave like a product
          shortName: name
          bottleImage: packageImage {
            ...ImageWithPreview
          }
        }
      }
    }
  `);
  const allPackageItems = data.allSanityPackageItem.nodes;
  const visiblePackageItems = allPackageItems.filter(
    (packageItem) => packageItem.isVisible
  );
  return visiblePackageItems.map((packageItem) => afterQuery(packageItem));
}

function afterQuery(packageItem) {
  if (!packageItem) return undefined;
  return {
    // countryCode: countryCodes[product.countryName],
    // producer: { name: product.producerName },
    ...packageItem,
  };
}
const countryCodes = { Deutschland: 'de' };
