/** @jsxRuntime classic */
/** @jsx jsx */
// @ts-check
import {
  CheckboxField,
  Form,
  TextAreaField,
  TextField,
} from '@bottlebooks/form';
import { Box, Button, Grid, Text } from '@bottlebooks/gatsby-theme-base';
import { graphql, navigate } from 'gatsby';
import React from 'react';
import { jsx } from 'theme-ui';
import { boolean, object, string } from 'yup';
import { RichText } from '..';
import Section from '../Layout/Section';

const schema = object().shape({
  name: string().required('Das ist ein Pflichtfeld'),
  email: string()
    .email('Bitte eine gültige E-Mail-Adresse eingeben.')
    .required('Das ist ein Pflichtfeld'),
  subject: string(),
  contactId: string(),
  message: string().required('Das ist ein Pflichtfeld'),
  acceptPrivacy: boolean().oneOf(
    [true],
    'Bitte die Datenschutzbestimmungen akzeptieren.'
  ),
});
export default function ContactSection({
  id = 'contact',
  title,
  subtitle,
  _rawText,
  to,
  subject,
  ...rest
}) {
  const [serverError, setServerError] = React.useState(null);
  async function handleSubmit(values) {
    try {
      const response = await fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams({ 'form-name': id, ...values }).toString(),
      });
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      navigate(`/danke`);
    } catch (error) {
      setServerError(error.message);
    }
  }

  return (
    <Section id={id} {...rest}>
      <Section.Title>{title}</Section.Title>
      <Text>{subtitle}</Text>
      <Grid gap={4} columns={[1, null, 2]}>
        <Section.Body>
          <RichText>{_rawText}</RichText>
        </Section.Body>
        <Form
          name={id}
          initialValues={{
            name: '',
            email: '',
            subject: '',
            contactId: '',
            message: '',
            acceptPrivacy: false,
          }}
          onSubmit={handleSubmit}
          validationSchema={schema}
          data-netlify="true"
          netlify-honeypot="bot-field"
          // data-netlify-recaptcha="true"
        >
          <input type="hidden" name="form-name" value={id} />
          <input type="hidden" name="to" value={to} />
          <label sx={{ display: 'none' }}>
            Don't fill this out if you're human: <input name="bot-field" />
          </label>
          <TextField name="name" label="Ihr Name" length={50} />
          <TextField name="email" label="Ihre E-Mail-Adresse" length={50} />
          <TextField name="subject" label="Anfragegrund" length={80} />
          <TextField
            name="contactId"
            label="Kontakt-ID"
            length={30}
            //   description="Wenn Sie bei einem Anschreiben eine Kontakt-ID erhalten haben, geben Sie diese bitte hier an."
          />
          <TextAreaField name="message" label="Ihre Nachricht" rows={10} />
          <CheckboxField
            name="acceptPrivacy"
            label="Ich akzeptiere die Datenschutzbestimmungen der Seite zur Bearbeitung meiner Anfrage"
          />
          <div data-netlify-recaptcha="true" />
          {serverError && (
            <Box sx={{ marginY: 2 }}>
              <Text sx={{ color: 'brand.warningRed' }}>
                Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.
              </Text>
              <Text variant="small" sx={{ color: 'brand.warningRed' }}>
                {serverError}
              </Text>
            </Box>
          )}
          <Button
            type="submit"
            variant="primary"
            sx={{ borderRadius: 'round' }}
          >
            Anfrage senden
          </Button>
        </Form>
      </Grid>
    </Section>
  );
}

export const fragment = graphql`
  fragment SanityContactSectionFragment on SanityContactFormSection {
    title
    subtitle
    subject
    to
    _rawText
  }
`;
