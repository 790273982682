/** @jsxRuntime classic */
/** @jsx jsx */
// @ts-check
import Layout from '@bottlebooks/gatsby-theme-event/src/components/Layout/Layout';
import { Box, Flex, Text, Title } from '@bottlebooks/gatsby-design-system';
import { Button } from '@bottlebooks/gatsby-theme-base';

import { graphql, useStaticQuery } from 'gatsby';
import { jsx, ThemeProvider } from 'theme-ui';
import * as cms from '../cms';
import * as designSystem from '../cms-design-system';
import virtuelleWeinTourLogo from '../assets/DWI21_VWT_Bubble_nodate_500.png';
import useEventTheme from '@bottlebooks/gatsby-theme-event/src/components/Layout/useEventTheme';
import BaseLayout from '@bottlebooks/gatsby-theme-event/src/components/Layout/BaseLayout';

export default function CaliforniaLandingPage() {
  const theme = useEventTheme();
  return (
    <ThemeProvider theme={theme}>
      <BaseLayout>
        <Flex
          align={[null, null, 'center']}
          sx={{ height: '100vh' }}
          direction={['column', 'column', 'row', 'row']}
        >
          <img
            src={virtuelleWeinTourLogo}
            sx={{ alignSelf: 'left', width: ['80%', '60%', 'auto'] }}
          />
          <Box sx={{ padding: 3 }}>
            <Title sx={{ marginY: 4 }}>Gleich geht's los!</Title>
            <Text sx={{ marginY: 4 }}>Am 1.2. sind wir so weit.</Text>
            <Text sx={{ marginY: 4 }}>
              Unsere Weintourzwerge machen jetzt die letzten Vorbereitungen für
              Sie!
            </Text>
            <Button variant="primary" href="https://www.weintour.net/">
              Erfahren Sie mehr
            </Button>
          </Box>
        </Flex>
      </BaseLayout>
    </ThemeProvider>
  );
}
