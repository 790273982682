/** @jsxRuntime classic */
/** @jsx jsx */
// @ts-check
import {
  Box,
  Flex,
  Grid,
  Link,
  P,
  SemiTitle,
  Text,
} from '@bottlebooks/gatsby-theme-base';
import Layout from '@bottlebooks/gatsby-theme-event/src/components/Layout/Layout';
import { graphql } from 'gatsby';
import Image from 'gatsby-plugin-sanity-image';
import { jsx } from 'theme-ui';
import videoImage from '../assets/videoImage.png';
import bannerImage from '../assets/WeinTour_TV_Web_23.jpg';
import { Section, Slider } from '../cms-design-system';

const videos = {
  rheinhessen: '5SR3Nr7Kplg',
  ahr: 'KTD6FF4Be3A',
  wuerttemberg: '773bQj0vEhQ',
  baden: 'LzjjBF6vgA0',
  hessischeBergstrasse: '01xnWNItL_8',
  franken: 'ayjRPV1hfXE',
  mittelrhein: 'xbIXJNVY6og',
  mosel: 'PToLD3ZVMxw',
  nahe: '1XTXpqT6Mrw',
  pfalz: 'kQEHQwoO3CQ',
  rheingau: 'kVwtQUbME9c',
  saaleUnstrut: 'WDAZ6y773nE',
  sachsen: 'QKCP5Rp_zCY',
};

export default function WeinTourTv({ data }) {
  const packages = data?.allSanityPackageItem?.nodes;
  const filteredPackages = packages.filter(
    ({ webinarVideoUrl }) => !!webinarVideoUrl
  );

  return (
    <Layout page={{ title: 'WeinTour TV' }}>
      <Slider
        id="banner"
        imageUrl={bannerImage}
        title={
          'Aufzeichnungen der Online-Verkostungen und Videos über die 13 Deutschen Anbaugebiete'
        }
        sx={{
          height: [287, 315, 350, 400],
        }}
      />
      <Section>
        <Section.Title sx={{ marginBottom: 3 }}>
          Archiv der Online-Verkostungen
        </Section.Title>
        <Section.Body sx={{ position: 'relative' }}>
          {filteredPackages.length ? (
            <Box sx={{ marginBottom: 4 }}>
              <SemiTitle
                sx={{
                  flexShrink: 0,
                  marginBottom: 3,
                }}
              >
                Videos aus den laufenden Serien
              </SemiTitle>
              <Grid columns={[2, 3, 4]} gap={3}>
                {filteredPackages.map((packageItem) => {
                  return (
                    <Link href={packageItem.webinarVideoUrl}>
                      <Box
                        sx={{
                          border: 1,
                          borderColor: 'border',
                          transition: 'border',
                          ':hover': { borderColor: 'primary' },
                        }}
                      >
                        <Image
                          {...packageItem.packageImage}
                          width={240}
                          sizes="240px"
                          alt={packageItem.name}
                          sx={{ width: '100%', display: 'block' }}
                          config={{ quality: 100 }}
                        />
                      </Box>
                    </Link>
                  );
                })}
              </Grid>
            </Box>
          ) : (
            <Box sx={{ marginBottom: 5 }}>
              <Flex direction={['column', 'row', 'row', 'row']}>
                <Box>
                  <img src={videoImage} style={{ width: '100%' }} />
                </Box>
                <Box
                  sx={{
                    width: ['100%', '70%', '70%', '70%'],
                    marginLeft: [0, 3, 3, 3],
                  }}
                >
                  <SemiTitle
                    sx={{
                      flexShrink: 0,
                    }}
                  >
                    Videos aus Hamburg 2022
                  </SemiTitle>
                  <Text>
                    Videos zu den digitalen Verkostungen aus Hamburg werden am
                    19. November verfügbar sein.
                  </Text>
                </Box>
              </Flex>
            </Box>
          )}
          <SemiTitle
            sx={{
              flexShrink: 0,
              marginBottom: 3,
            }}
          >
            Videos aus den vorherigen Serien
          </SemiTitle>
          <Grid gap={3} columns={[1, null, 3]}>
            <Link
              to={
                'https://www.youtube.com/watch?v=as7Lk8v1Mcs&list=PLF5v7VIvLj4jou4s7g61-RVbawHPYKu-v'
              }
              target="_blank"
            >
              <Box sx={{ width: '100%', position: 'relative' }}>
                <img src={videoImage} style={{ width: '100%' }} />
                <SemiTitle sx={{ margin: 0 }}>
                  WeinTour Frühjahr 2022 auf YouTube
                </SemiTitle>
              </Box>
            </Link>
            <Link
              to={
                'https://www.youtube.com/playlist?list=PLF5v7VIvLj4gPv_AF4k8jgssZJqTGXiaP'
              }
              target="_blank"
            >
              <Box sx={{ width: '100%', position: 'relative' }}>
                <img src={videoImage} style={{ width: '100%' }} />
                <SemiTitle sx={{ margin: 0 }}>
                  WeinTour Herbst 2021 auf YouTube
                </SemiTitle>
              </Box>
            </Link>
            <Link
              to={
                'https://www.youtube.com/playlist?list=PLF5v7VIvLj4gUl8g5uq9nd_UvWR17MplG'
              }
              target="_blank"
            >
              <Box sx={{ width: '100%' }}>
                <img src={videoImage} style={{ width: '100%' }} />
                <SemiTitle sx={{ margin: 0 }}>
                  WeinTour Frühjahr 2021 auf YouTube
                </SemiTitle>
              </Box>
            </Link>
            <Link
              to={
                'https://www.youtube.com/playlist?list=PLF5v7VIvLj4iNaLpdel7X1ZVK4tp1hB_j'
              }
              target="_blank"
            >
              <Box sx={{ width: '100%' }}>
                <img src={videoImage} style={{ width: '100%' }} />
                <SemiTitle sx={{ margin: 0 }}>
                  WeinTour 2020 auf YouTube
                </SemiTitle>
              </Box>
            </Link>
          </Grid>
        </Section.Body>
      </Section>
      <Section>
        <Section.Title sx={{ marginBottom: 3 }}>
          Willkommen in den besten Lagen!
        </Section.Title>
        <Section.Body>
          <Flex direction={['column', 'column', 'row', 'row']}>
            <Box sx={{ flex: 1, paddingRight: [0, 0, 3, 3] }}>
              <P>
                Der Film “Willkommen in den besten Lagen” präsentiert in
                kurzweiliger Form und mit emotionalen Bildern die 13
                Weinregionen, zeigt das Jahr im Weinberg, die Arbeit im Keller
                und gibt Tipps zur Harmonie von Wein und Essen. Zahlreiche
                Protagonisten wie Winzer, Kellermeister, Sommeliers,
                Weinkritiker und Journalisten kommen dabei zu Wort.
              </P>
              <P>
                Die DVD mit dem 30-minütigen Film sowie Einzelportraits aller 13
                deutschen Anbaugebiete ist erhältlich im{' '}
                <Link
                  to={'http://shop.deutscheweine.de/index.ph'}
                  target="_blank"
                >
                  Shop
                </Link>{' '}
                des Deutschen Weininstituts.
              </P>
            </Box>
            <Box
              sx={{
                flexShrink: 1,
                width: ['100%', '100%', 560, 560],
                height: ['45vw', '45vw', '30vw', '25vw'],
                position: 'relative',
              }}
            >
              <iframe
                width="100%"
                height="100%"
                title="Video description"
                src={`https://www.youtube.com/embed/doIZST5K_6s`}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                sx={{
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                }}
              ></iframe>
            </Box>
          </Flex>
        </Section.Body>
      </Section>
      <Section>
        <Section.Title sx={{ marginBottom: 3 }}>
          Lernen Sie die 13 Deutschen Weinregionen kennen
        </Section.Title>
        <Section.Body>
          {/* Grid doesn't seem to support marginBottom, so we have to wrap in Box */}
          <Box sx={{ marginBottom: 3 }}>
            <Grid columns={[1, 1, 2, 2]} gap={3}>
              <Box>
                <Text>
                  Der Tourismus rund um das Thema Weinbau hat sich in vielen
                  deutschen Weinregionen in den letzten Jahren positiv
                  entwickelt und stellt nicht zuletzt für ländliche Regionen ein
                  wichtiges Standbein dar. Wir freuen uns daher, wenn Sie auch
                  vor Ort die Gelegenheit nutzen, unsere Weinbaugebiete
                  kennenzulernen.
                </Text>
              </Box>
              <Box>
                <Text>
                  Es wartet eine Vielfalt an Qualitätsweinen, touristischen
                  Angeboten, kulturellen Sehenswürdigkeiten sowie kulinarischen
                  Köstlichkeiten darauf entdeckt zu werden – präsentiert von den
                  Menschen der Region. Aber sehen Sie doch selbst…
                </Text>
              </Box>
            </Grid>
          </Box>
          <Grid columns={[1, null, 3]} gap={3}>
            {Object.values(videos).map((videoId) => (
              <YouTubeVideo key={videoId} videoId={videoId} />
            ))}
          </Grid>
        </Section.Body>
      </Section>
    </Layout>
  );
}

function YouTubeVideo({ videoId }) {
  return (
    <Box
      sx={{
        position: 'relative',
        height: ['45vw', '30vw', '18vw', '15vw'],
      }}
    >
      <iframe
        width="100%"
        height="100%"
        title="Video description"
        src={`https://www.youtube.com/embed/${videoId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        sx={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
      ></iframe>
    </Box>
  );
}

function getVideoType(videoUrl) {
  const [, , , , , videoId] =
    videoUrl.match(
      /(youtu\.be\/|youtube\.com\/(watch\?(.+&)?v=|(embed|v)\/))([^?&"'>]+)/
    ) || [];
  return { type: 'YOUTUBE', videoId };
}

export const query = graphql`
  query WeinTourTvPage {
    event {
      eventId
    }
    allSanityPackageItem(sort: { fields: [packageNumber], order: [ASC] }) {
      nodes {
        _id
        slug {
          current
        }
        name
        categories
        webinarDate
        webinarVideoUrl
        speaker {
          ...SpeakerProfile
        }
        # host {
        #   ...SpeakerProfile
        # }
        ...PackageListItem
      }
    }
  }
`;
