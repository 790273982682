/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
// @ts-check
import BaseBaseLayout from '@bottlebooks/gatsby-theme-event/src/components/Layout/BaseLayout';
import useEventTheme from '@bottlebooks/gatsby-theme-event/src/components/Layout/useEventTheme';
import SEO from '@bottlebooks/gatsby-theme-event/src/components/seo.js';
import React from 'react';
import { Global, css } from '@emotion/core';
import { jsx } from 'theme-ui';
import CookieBanner from './CookieBanner';

export default function BaseLayout({
  children,
  page: basePage = { title: undefined },
  ...rest
}) {
  const defaultTitle = 'wein.club - Online-Tastings für b2b und b2c';
  const page = {
    ...basePage,
    title: basePage.title
      ? `${basePage.title} | ${defaultTitle}`
      : `${defaultTitle}`,
  };
  const theme = useEventTheme();
  return (
    <>
      <SEO page={page} />

      <Global
        styles={css/* CSS */ `
          html {
            /* Default */
            --color-buttonPrimary: ${theme.colors.onPrimary};
            --borderColor-buttonPrimary: ${theme.colors.primary};
            --bgColor-buttonPrimary: ${theme.colors.primary};

            /* Hover */
            --color-buttonPrimary-hover: ${theme.colors.onPrimary};
            --borderColor-buttonPrimary-hover: ${theme.colors.secondary};
            --bgColor-buttonPrimary-hover: ${theme.colors.secondary};
            --shadow-buttonPrimary-hover: none;

            /* Active */
            --color-buttonPrimary-active: ${theme.colors.onPrimary};
            --borderColor-buttonPrimary-active: ${theme.colors.primary};
            --bgColor-buttonPrimary-active: ${theme.colors.primary};
            --shadow-buttonPrimary-active: none;

            /* Focus */
            --color-buttonPrimary-focus: ${theme.colors.onPrimary};
            --borderColor-buttonPrimary-focus: ${theme.colors.secondary};
            --bgColor-buttonPrimary-focus: ${theme.colors.secondary};
            --shadow-buttonPrimary-focus: none;

            --color-buttonSecondary: ${theme.colors.onPrimary};
            --borderColor-buttonSecondary: ${theme.colors.secondary};
            --bgColor-buttonSecondary: #e9e9e9;

            --color-buttonSecondary-hover: ${theme.colors.onPrimary};
            --bgColor-buttonSecondary-hover: ${theme.colors.secondary};

            /* Default */
            --color-link: ${theme.colors.primary};
            --color-link-hover: ${theme.colors.secondary};
            --color-link-active: ${theme.colors.primary};
            --color-link-focus: ${theme.colors.secondary};

            /* Default (completed) */
            --color-badge: ${theme.colors.primary};

            /* Active */
            --color-badge-active: white;
            --borderColor-badge-active: ${theme.colors.primary};
            --bgColor-badge-active: ${theme.colors.primary};

            /* Default */

            --borderColor-default: ${theme.colors.primary};
            --color-icon: ${theme.colors.primary};
            --color-success: ${theme.colors.primary};

            --borderColor-input-hover: ${theme.colors.secondary};
            --borderColor-input-focus: ${theme.colors.secondary};
            --borderColor-hover: ${theme.colors.primary};

            --borderColor-input-checked: ${theme.colors.primary};
            --bgColor-input-checked: ${theme.colors.primary};

            --color-inputIcon: ${theme.colors.primary};
            --color-inputIcon-checked: ${theme.colors.primary};
          }
        `}
      />

      <BaseBaseLayout {...rest}>
        {' '}
        <CookieBanner />
        {children}
      </BaseBaseLayout>
    </>
  );
}
