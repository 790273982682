/** @jsx jsx */
import { Link } from '@bottlebooks/gatsby-theme-base';
import { graphql, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';
import { jsx } from 'theme-ui';

/**
 *
 * @param {object} props
 * @param {string } [props.to]
 * @param {boolean} [props.isScrolledDown]
 * @returns
 */
export default function Logo({ to, isScrolledDown, ...rest }) {
  const { event } = useStaticQuery(query);
  const { logo, name } = event || {};
  if (!logo) return null;
  if (!to) return <Image fixed={logo.fixed} fadeIn={false} alt={name} />;
  return (
    // On logos, it's allowed to use external links on 'to' (to possibly link to a main website).
    // The Link component takes care of that. We need to suppress the warning though.
    <Link to={to} _suppressExternalLinkWarning {...rest}>
      <Image fixed={logo.fixed} fadeIn={false} alt={name} />
    </Link>
  );
}

const query = graphql`
  {
    event {
      name
      logo {
        fixed(
          width: 120
          height: 40
          crop: PAD
          background: "#FFF0"
          quality: BEST
          gravity: WEST
        ) {
          src
          srcSet
          width
          height
        }
      }
    }
  }
`;
