// @ts-check
import { graphql, useStaticQuery } from 'gatsby';

/**
 * @returns
 */
export default function useExhibitors() {
  const data = useStaticQuery(graphql`
    query useExhibitors {
      allSanityExhibitor {
        nodes {
          isVisible
          exhibitorId: _id
          ...sanity_ExhibitorHeader
        }
      }
    }
  `);
  const allExhibitors = data.allSanityExhibitor.nodes;
  const visibleExhibitors = allExhibitors.filter(
    (exhibitor) => exhibitor.isVisible
  );
  return visibleExhibitors.map((exhibitor) => afterQuery(exhibitor));
}

function afterQuery(exhibitor) {
  if (!exhibitor) return undefined;
  return {
    ...exhibitor,
  };
}
const countryCodes = { Deutschland: 'de' };
