/** @jsxRuntime classic */
/** @jsx jsx */
// @ts-check
import { Box } from '@bottlebooks/gatsby-design-system';
import ProductListRow from '@bottlebooks/gatsby-theme-event/src/components/ProductsList/ProductListRow';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';
import { countryCodes } from './countryCodes';

export default function WineListItem({ product, ...rest }) {
  const wine = afterQuery(product);
  if (!wine) return null;
  return (
    <Box
      sx={{
        marginX: ['-gutter', 0],
        marginBottom: [-3, 0],
        paddingBottom: 'px', // needed to compensate a -1px margin-bottom.
      }}
    >
      <ProductListRow
        product={{ ...wine, bottleSize: null }}
        sx={{ flexGrow: 1 }}
        {...rest}
      />
    </Box>
  );
}

export const fragment = graphql`
  fragment WineListItem on SanityProduct {
    productId: _id
    ...sanity_ProductListRow
    ...sanity_ProductHeader
  }

  fragment sanity_ProductListRow on SanityProduct {
    shortName: name
    vintage
    ...sanity_ProductImage
    denomination: region
    producerName #TODO change to producer
    productNumber
    designation
    stand {
      name
    }
    classification
    countryName: country
  }
`;

function afterQuery(product) {
  if (!product) return undefined;
  return {
    countryCode: countryCodes[product.countryName],
    producer: { name: product.producerName },
    ...product,
  };
}
