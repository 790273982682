export const countryCodes = {
  Andora: 'ad',
  Angola: 'ao',
  Albanien: 'al',
  Algerien: 'dz',
  Afghanistan: 'af',
  Argentinien: 'ar',
  Armenien: 'am',
  Australien: 'au',
  Azerbaijan: 'az',
  Bahrain: 'bh',
  Weißrussland: 'by',
  Belgien: 'be',
  'Bosnien und Herzegovina': 'ba',
  Botswana: 'bw',
  Bolivien: 'bo',
  Brasilien: 'br',
  Brunei: 'bn',
  Bulgarien: 'bg',
  'Burkina Faso': 'bf',
  Kambodscha: 'kh',
  Kamerun: 'cm',
  Chile: 'cl',
  China: 'cn',
  Kolumbien: 'co',
  Cuba: 'cu',
  Dänemark: 'dk',
  Deutschland: 'de',
  'Democratische Republik Kongo': 'cd',
  Ecuador: 'ec',
  Ägypten: 'eg',
  England: 'gb_eng',
  Äthiopien: 'et',
  Finnland: 'fi',
  Frankreich: 'fr',
  Georgien: 'ge',
  Ghana: 'gh',
  Griechenland: 'gr',
  Großbritannien: 'gb',
  Guyana: 'gy',
  'Hong Kong': 'hk',
  Honduras: 'hn',
  Irland: 'ie',
  Indien: 'in',
  Indonesien: 'id',
  Iran: 'ir',
  Irak: 'iq',
  Israel: 'il',
  Italien: 'it',
  Island: 'is',
  Elfenbeinküste: 'ci',
  Japan: 'jp',
  Jordanien: 'jo',
  Kanada: 'ca',
  Kroatien: 'hr',
  Kasachstan: 'kz',
  Kenia: 'ke',
  Kosovo: 'xk',
  Kuwait: 'kw',
  Kirgistan: 'kg',
  Laos: 'la',
  Libanon: 'lb',
  Luxemburg: 'lu',
  Lettland: 'lv',
  Libyen: 'ly',
  Moldawien: 'md',
  Montenegro: 'me',
  Mazedonien: 'mk',
  Madagascar: 'mg',
  Malaysia: 'my',
  Mali: 'ml',
  Malediven: 'mv',
  Malta: 'mt',
  Mauritius: 'mu',
  Mexiko: 'mx',
  Mongoleu: 'mn',
  Marokko: 'ma',
  Mosambik: 'mz',
  Myanmar: 'mm',
  Nepal: 'np',
  Neuseeland: 'nz',
  Niederlande: 'nl',
  Nigeria: 'ng',
  Nordirland: 'gb_nir',
  Norwegen: 'no',
  Österreich: 'at',
  Panama: 'pa',
  Paraguay: 'py',
  Peru: 'pe',
  Polen: 'pl',
  Portugal: 'pt',
  Philippinen: 'ph',
  Rumänien: 'ro',
  Russland: 'ru',
  'San Marino': 'sm',
  'Saint Lucia': 'lc',
  Schweiz: 'ch',
  Serbien: 'rs',
  Senegal: 'sn',
  Singapur: 'sg',
  Slowakei: 'sk',
  Slowenien: 'si',
  Südkorea: 'kr',
  Spanien: 'es',
  'Sri Lanka': 'lk',
  Südafrika: 'za',
  Schweden: 'se',
  Schottland: 'gb_sct',
  Sudan: 'sd',
  Surinam: 'sr',
  Syrien: 'sy',
  Taiwan: 'tw',
  Tansania: 'tz',
  Thailand: 'th',
  Tschechien: 'cz',
  Tunesien: 'tn',
  'Trinidad und Tobago': 'tt',
  Türkei: 'tr',
  Ukraine: 'ua',
  Ungarn: 'hu',
  Uruguay: 'uy',
  USA: 'us',
  Usbekistan: 'uz',
  Uganda: 'ug',
  Venezuela: 'yv',
  'Vereinigte Arabische Emirate': 'ae',
  Vietnam: 'vn',
  Wales: 'gb_wls',
  Sambia: 'zm',
  Simbabwe: 'zw',
  Zypern: 'cy',
};
